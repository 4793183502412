import { FC, useCallback, useMemo } from 'react';
import { ThemeColor } from 'styles';
import { LineProgress, Container, Bar, IconCoin, BarContainer, ProgressText } from './styles';
import Icon, { IconType } from '../../UI/Icon';
import coinBadge from 'assets/vectors/wellr-badge.svg';
import { useLocalStorage } from 'hooks/useLocalStorage';

type Props = {
  progress: number | null;
  color?: ThemeColor | string;
  height?: number;
  rounded?: boolean;
  icon?: IconType;
  iconProgressBar?: any;
  textProgressBar?: string;
  amountToComplete?: number;
  contentGoal?: boolean;
  pushGoal?: boolean;
  goalCompleted?: boolean;
  style?: any;
  onClickComplete?: () => void;
};

const ProgressBarIcon: FC<Props> = ({ progress, color = 'blue', height, rounded = false, icon, amountToComplete = 0, contentGoal, goalCompleted, iconProgressBar, textProgressBar, onClickComplete , pushGoal}) => {

  // State
  const [unlocked, setUnlocked] = useLocalStorage<boolean>('unlocked', false);

  const numberFormatter = useMemo(() => new Intl.NumberFormat('en-US'), []);

  const progressText = useMemo(() => {
    if (textProgressBar) {
      return textProgressBar;
    }
    const formatNumber = (number: number) => numberFormatter.format(number);

    if (amountToComplete && progress !== null && !goalCompleted) {
      const calculatedValue = Math.round((progress / 100) * amountToComplete);
      const formattedAmountToComplete = formatNumber(amountToComplete);
      const formattedCalculatedValue = formatNumber(calculatedValue);

      return calculatedValue >= amountToComplete
        ? `${formattedAmountToComplete}/${formattedAmountToComplete}`
        : `${formattedCalculatedValue}/${formattedAmountToComplete}`;
    }

    if (contentGoal) {
      return goalCompleted ? '1/1' : '0/1';
    }

    if (goalCompleted && amountToComplete) {
      const formattedAmountToComplete = formatNumber(amountToComplete);
      return `${formattedAmountToComplete}/${formattedAmountToComplete}`;
    }

    return `${progress}%`;
  }, [textProgressBar, amountToComplete, progress, goalCompleted, contentGoal, numberFormatter]);

  // Handle complete action
  const handleComplete = useCallback(async () => {
    if (onClickComplete && unlocked) {
      await onClickComplete();
    }
    await setUnlocked(false);
  }, [onClickComplete, setUnlocked, unlocked]);

  const lines = Array.from({ length: (amountToComplete ?? 1) - 1 }, (_, i) => (
    <LineProgress key={i} position={((i + 1) / amountToComplete) * 100} />
  ));

  return (
    <Container height={height} rounded={rounded}>
      <BarContainer onClick={handleComplete} completed={goalCompleted} unlocked={unlocked} height={height} rounded={rounded}>
        {pushGoal && lines}
        <Bar
          height={height}
          initial={{ width: 0 }}
          animate={{ width: goalCompleted ? '100%' : `${progress ?? 0}%` }}
          transition={{ duration: 1 }}
          color={color}
        />

      </BarContainer>
      <ProgressText
        onClick={handleComplete}
        unlocked={unlocked}>
        {icon && contentGoal && !goalCompleted ?
          <Icon type={icon} />
          :
          progressText
        }</ProgressText>
      <IconCoin>
        {iconProgressBar ?
          <Icon type={iconProgressBar} />
          :
          <img src={coinBadge} alt="points badge" />
        }
      </IconCoin>
    </Container>
  );
};

export default ProgressBarIcon;