import { FC, useMemo } from 'react';
import { ContentImage, Reference, ReferenceType } from 'models';
import Icon, { IconType } from 'components/UI/Icon';
import animationData from 'assets/animations/sparks-animation.json';

import {
  Action,
  ButtonContent,
  Card,
  CardContent,
  CardText,
  ImageView,
  Overlay,
  CardTitle,
  IconContainer,
  StyledLottie,
  Description,
  ProgressContainer,
  Row,
  ImageContainer,
  CardContainer,
  CardHeader,
  CardTextHeader,
} from './styles';
import { ChallengeType } from 'models/timedChallengeResult/timedChallengeResult';
import Heading, { Tag } from 'components/UI/Heading';
import { FormattedMessage, useIntl } from 'react-intl';
import { getImage } from 'utils/asset';
import WellrCoins from 'components/UI/WellrCoins';
import Button from 'components/UI/Button';
import ProgressBarIcon from 'components/fragments/ProgressBarIcon';
import { getRefTypeIcon } from 'utils/link';
import useIsMobile from 'hooks/useIsMobile';
import { useLocalStorage } from 'hooks/useLocalStorage';

type Props = {
  image?: ContentImage | null;
  title: string;
  wellrPoints: number;
  challengeType: ChallengeType;
  description?: string;
  reference?: Reference;
  progress?: number;
  amountToComplete?: number;
  goalCompleted: boolean;
  onClickContent: (type: ReferenceType, slug: string) => () => void;
  onClickComplete: () => void;
  onClickProgress: () => void;
};

const TimedChallengeCard: FC<Props> = ({
  image,
  title,
  challengeType,
  description,
  reference,
  progress,
  wellrPoints,
  amountToComplete,
  goalCompleted,
  onClickContent,
  onClickComplete,
  onClickProgress
}) => {
  // Hooks
  const intl = useIntl();
  const isMobile = useIsMobile();
  const [unlocked] = useLocalStorage<boolean>('unlocked', false);

  const isContentGoal = useMemo(() => challengeType === ChallengeType.Content, [challengeType]);
  const isPushGoal = useMemo(() => challengeType === ChallengeType.Push, [challengeType]);

  // Memoized values
  const arrowIcon = useMemo(() => {
    return challengeType === ChallengeType.Content ? <Icon type={IconType.Arrow} /> : null;
  }, [challengeType]);

  const iconRefType = useMemo(() => {
    if (reference && challengeType === ChallengeType.Content) {
      return getRefTypeIcon('article');
    }
    if (challengeType === ChallengeType.Activity.toLocaleLowerCase()) {
      return getRefTypeIcon('exercise');
    }
    return getRefTypeIcon(reference?._type ?? 'article');
  }, [reference, challengeType]);

  const imageSrc = useMemo(() => image && getImage(image), [image]);

  const shouldDisplayImage = useMemo(() => image && !isMobile, [image, isMobile]);

  const handleOnClickCompletePushGoal = () => {
    if ((progress ?? 0) < 1) {
      onClickComplete();
      return;
    }
    onClickProgress();
  };

  return (
    <Card inverted={false}>
      {shouldDisplayImage && (
        <ImageContainer>
          <ImageView src={imageSrc!.src} alt={imageSrc!.alt} />
          <Overlay />
        </ImageContainer>
      )}
      <CardContainer>
        <CardContent center={!shouldDisplayImage}>
          <CardHeader>
            <CardTitle>
              {intl.formatMessage({
                id: 'challengeCardDailyChallengeTitle',
                defaultMessage: 'Daily Challenge',
                description: 'Daily Challenge title',
              })}
            </CardTitle>
            <WellrCoins count={wellrPoints} right />
          </CardHeader>
          <Row>
            <CardText>
              <CardTextHeader>
                <Heading tag={Tag.H3}>{title}</Heading>
                {(!image || isMobile) && (
                  <IconContainer>
                    <Icon type={iconRefType} color="blue" />
                  </IconContainer>
                )}
              </CardTextHeader>
              <Description>{description}</Description>
            </CardText>
          </Row>
        </CardContent>
        <ProgressContainer>
          {goalCompleted || (progress && progress >= 100) ?
            <StyledLottie
              loop={false}
              animationData={animationData}
            /> : null
          }
          <ProgressBarIcon
            progress={progress ?? 0}
            color="goldBright"
            height={30}
            icon={!unlocked ? IconType.LockClosed : undefined}
            amountToComplete={amountToComplete}
            contentGoal={isContentGoal}
            pushGoal={isPushGoal}
            goalCompleted={goalCompleted}
            onClickComplete={onClickComplete}
            textProgressBar={
              unlocked && !goalCompleted
                ? intl.formatMessage({
                  id: 'pageOverviewProgressTextDone',
                  defaultMessage: 'Tryck här för att slutföra!',
                  description: 'Progress text',
                })
                : undefined
            }
            rounded
          />
        </ProgressContainer>
        {isContentGoal && (
          <Action>
            <Button
              color="blue"
              background="transparent"
              onClick={onClickContent(reference?._type as ReferenceType, reference?.slug.current as string)}
            >
              <ButtonContent >
                <FormattedMessage
                  id="pageOverviewReadHereButton"
                  defaultMessage="Read here"
                  description="Read here button text"
                />
                {arrowIcon}
              </ButtonContent>
            </Button>
          </Action>
        )}
        {isPushGoal && !goalCompleted && (
          <Action>
            <Button
              color="blue"
              background="transparent"
              onClick={handleOnClickCompletePushGoal}
            >
              {amountToComplete && amountToComplete > 1 ? (
                <ButtonContent>
                  <FormattedMessage
                    id="pageOverviewDailyChallengePushGoal"
                    defaultMessage="Gå vidare till nästa steg!"
                    description="Progress text"
                  />
                  {arrowIcon}
                </ButtonContent>
              ) : (
                <ButtonContent >
                  <FormattedMessage
                    id="pageOverviewProgressTextDone"
                    defaultMessage="Tryck här för att slutföra!"
                    description="Progress text"
                  />
                  {arrowIcon}
                </ButtonContent>
              )}
            </Button>
          </Action>
        )}
      </CardContainer>
    </Card>
  );
};

export default TimedChallengeCard;