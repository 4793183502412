import { FC, Fragment, useCallback, useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  localizationSelector,
  setLayoutHeader,
  useDeleteContestMeMutation,
  useGetContestQuery,
  useGetContestSlugQuery,
  useGetUserQuery,
  useToggleMyEmailDisplayMutation,
} from 'store';

import * as routes from 'router/routes';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ContentWidth, Gap } from 'styles';
import { checkShowRegisterActivities } from 'utils/contest';
import { getRefLink } from 'utils/link';
import { getImage } from 'utils/asset';
import { isAfter } from 'utils/date';

import { IconType } from 'components/UI/Icon';
import ChatModal from 'components/modals/ChatModal';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import ContestHeader from 'components/fragments/ContestHeader';
import Heading, { Tag } from 'components/UI/Heading';
import Button from 'components/UI/Button';
import LastActivities from 'components/fragments/LastActivities';
import DragSlider from 'components/UI/DragSlider';
import ImageCard from 'components/UI/Cards/ImageCard';
import RegisterActivityModal from 'components/modals/RegisterActivityModal';
import ContentModal from 'components/modals/ContentModal';

import RichText from 'components/fragments/RichText';
import ChallengeTopList from 'components/fragments/ChallengeTopList';
import HealthGoals from 'components/fragments/HealthGoals';

import { ButtonGrid, Grid, InviteList } from './styles';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup/ConfirmActionPopup';
import ExpandableCard from 'components/UI/Cards/ExpandableCard';
import { isWithinInterval } from 'date-fns';
import { MapGoal } from 'components/fragments/CompetitionStatus/styles';
import HealthChoices from 'components/fragments/HealthChoices';
import ContestMap from 'components/fragments/ContestMap';
import StaticCard from 'components/UI/Cards/StaticCard';
import { ContestTeamUser } from 'models';
import { useGetUnreadChatRoomEntriesQuery } from 'store/chatService/chatService';
import HeadingWithAdd from 'components/UI/HeadingWithAdd';
import AddChallengeParticipantModal from 'components/modals/AddChallengeParticipantModal';

const Challenge: FC = () => {
  const intl = useIntl();
  const { contestId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const { language } = useAppSelector(localizationSelector);
  const [showConfirmDeleteContest, setShowConfirmDeleteContest] =
    useState<boolean>(false);

  // Hooks
  const [toggleMyEmailDisplayMutation] = useToggleMyEmailDisplayMutation();
  const { data, isLoading, isFetching } = useGetContestQuery(
    {
      contestId,
      language: language?.languageCode,
    },
  );
  const { userId } = useGetUserQuery(undefined, {
    selectFromResult: ({ data }) => ({ userId: data?.id }),
  });
  const [deleteContest] = useDeleteContestMeMutation();
  const { data: unreadMessages, refetch: refetchUnreadMessages } = useGetUnreadChatRoomEntriesQuery(
    { chatRoomId: contestId ?? '' },
    { skip: contestId === null || contestId === undefined }
  );
  const contestSlug = useGetContestSlugQuery({ contestId: contestId ?? '' });

  // State
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false);
  const [aboutModalOpen, setAboutModalOpen] = useState<boolean>(false);
  const [showHideEmailButtonMessage, setShowHideEmailButtonMessage] = useState<boolean>();
  const [isAddParticipantModalOpen, setIsAddParticipantModalOpen] = useState<boolean>(false);

  const onDeleteContest = useCallback(() => {
    deleteContest({ contestId: contestId as string });
    navigate(routes.COMPETE);
  }, [deleteContest, navigate, contestId]);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageCompeteTitle',
          defaultMessage: 'Compete',
          description: 'Compete page title',
        }),
        reminder: { slug: contestSlug.data?.slug ?? '', redirectUrl: window.location.href },
        inverted: true,
        icon: IconType.Back,
        link: routes.COMPETE,
      })
    );
  }, [contestSlug.data?.slug, dispatch, intl]);

  // Actions
  const onOpenRegisterModal = useCallback(() => setRegisterModalOpen(true), []);
  const onCloseRegisterModal = useCallback(
    () => setRegisterModalOpen(false),
    []
  );
  const onOpenAboutModal = useCallback(() => setAboutModalOpen(true), []);
  const onCloseAboutModal = useCallback(() => setAboutModalOpen(false), []);
  const onNavigate = useCallback(
    (link: string) => () => navigate(link),
    [navigate]
  );

  const showRegisteredActivitiesSection = useMemo(
    () => checkShowRegisterActivities(data?.goals ?? []),
    [data]
  );

  const onCloseAddParticipantModal = useCallback(() => {
    setIsAddParticipantModalOpen(false);
  }, []);


  const onHideEmail = () => {
    toggleMyEmailDisplayMutation({ contestUserId: data?.me.id ?? '' });
  }

  useEffect(() => {
    const isEmailHidden = data?.me && data?.toplist?.some((user: ContestTeamUser) => user.id === data.me.id && user.email === null);
    setShowHideEmailButtonMessage(!!isEmailHidden);
  }, [data?.me, data?.toplist]);

  const pinkCompetition = useMemo(() => {
    if (!data || !data.title) {
      return false;
    }

    return Object.values(data.title).some((text) =>
      text.toLowerCase().includes('pink')
    );
  }, [data]);

  const showRegisterActivityButton = useMemo(
    () =>
      data &&
      isAfter(data.start) &&
      showRegisteredActivitiesSection &&
      data.completed == null,
    [data, showRegisteredActivitiesSection]
  );

  // Goals
  const challengeGoals = useMemo(() => {
    if (!data || !data.goals.length) {
      return null;
    }

    // First goal
    const { id, goals, users, me, totalAmount } = data;
    const [firstGoal] = goals;

    // Map
    if (firstGoal.type === 'A_b') {
      return (
        <Fragment>
          <StaticCard>
            <Grid>
              <ContestMap
                goals={goals}
                participants={users ?? []}
                myParticipantId={me.id}
                useRankColors={false}
                pinkCompetition={pinkCompetition}
              />
            </Grid>
          </StaticCard>
          <MapGoal>
            <FormattedMessage
              id="contestMapGoal"
              defaultMessage="Step goal: {totalAmount}"
              description="Contest status text for map step goal"
              values={{ totalAmount: totalAmount }}
            />
          </MapGoal>
          <Gap />
        </Fragment>
      );
    }

    // Health choices
    if (firstGoal.type === 'FreeText') {
      const found = goals.find((goal) =>
        isWithinInterval(new Date(), {
          start: new Date(goal.start),
          end: new Date(goal.stop),
        })
      );

      if (found) {
        return (
          <HealthGoals
            contestId={id}
            goal={found}
            completed={me.completedGoals}
            isFetching={isFetching}
          />
        );
      }
    }

    return null;
  }, [data, isFetching, pinkCompetition]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data) {
    return (
      <EmptyState iconType={IconType.Competition} padding>
        <FormattedMessage
          id="pageChallengeEmptyState"
          defaultMessage="Challenge not found"
          description="Empty state for challenge"
        />
      </EmptyState>
    );
  }

  const {
    title,
    description,
    wellrPoints,
    start,
    stop,
    image,
    goals,
    users,
    toplist,
    individualResults,
    reference,
    me: { id, userInvites, completedHealthChoices, pendingActivities },
  } = data;

  const [firstGoal] = goals;

  return (
    <Fragment>
      <ContestHeader
        title={title}
        categoryTitle={intl.formatMessage({
          id: 'pageChallengeCategoryTitle',
          defaultMessage: 'Challenge',
          description: 'Challenge category title',
        })}
        image={image}
        start={start}
        stop={stop}
        wellrPoints={wellrPoints}
        languageCode={language?.languageCode}
      />
      <ContentWidth isSurface>
        <ButtonGrid>
          <Button
            color="blue"
            background="transparent"
            border="borderDark"
            onClick={onOpenAboutModal}
          >
            <FormattedMessage
              id="challengeAboutButton"
              defaultMessage="About the challenge"
              description="Challenge about button"
            />
          </Button>
          <Button
            color="blue"
            background="transparent"
            border="borderDark"
            onClick={onHideEmail}
          >
            {showHideEmailButtonMessage ? (
              <FormattedMessage
                id="showMyEmailButton"
                defaultMessage="Show my email"
                description="Show my email"
              />
            ) : (
              <FormattedMessage
                id="hideMyEmailButton"
                defaultMessage="Hide my email"
                description="Hides my email"
              />
            )}
          </Button>
          {showRegisterActivityButton && (
            <Button background="blue" onClick={onOpenRegisterModal}>
              <FormattedMessage
                id="registerActivityButton"
                defaultMessage="Register activity"
                description="Register activity button"
              />
            </Button>
          )}
        </ButtonGrid>
        <Gap />
        {userInvites != null && userInvites.length > 0 && (
          <ExpandableCard
            title={intl.formatMessage(
              {
                id: 'pageChallengeUserInvitesTitle',
                defaultMessage: 'Pending invitations ({count})',
                description:
                  'Title for user invites expandable card on challenge page',
              },
              { count: userInvites.length }
            )}
            id="userInvites"
          >
            <InviteList>
              {userInvites.map((invite) => (
                <div key={invite.email}>
                  {invite.firstName} {invite.lastName} ({invite.email})
                </div>
              ))}
            </InviteList>
          </ExpandableCard>
        )}
        <Gap />
        {challengeGoals}
        {goals.map((goal) => (
          <HealthChoices
            key={goal.id}
            contestId={contestId as string}
            goalId={goal.id}
            data={goal.healthChoices}
            completed={completedHealthChoices[goal.id]}
          />
        ))}
        {data.startedBy.userId === userId ? (
          <HeadingWithAdd
            title={intl.formatMessage({
              id: 'pageChallengeTopListTitle',
              defaultMessage: 'Top list',
              description: 'Title for top list section on challenge page',
            })}
            addTitle={intl.formatMessage({
              id: 'competitionAddParticipantButton',
              defaultMessage: 'Add participant',
              description: 'competition add participant button',
            })}
            onAdd={() => setIsAddParticipantModalOpen(true)}
          />
        ) : (
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="pageChallengeTopListTitle"
              defaultMessage="Top list"
              description="Title for top list section on challenge page"
            />
          </Heading>
        )}
        <ChallengeTopList
          individualResults={individualResults}
          list={toplist ?? []}
          goals={goals}
          users={users ?? []}
          userId={id}
          pendingActivity={pendingActivities}
          unit={data.unit ?? undefined}
        />
        <Gap />
        {showRegisteredActivitiesSection && (
          <LastActivities
            startDate={start}
            endDate={stop}
            allowedActivities={firstGoal.allowedActivities}
            allowAllActivities={firstGoal.allowAllActivities}
          />
        )}

        {data.startedBy.userId === userId && (
          <Fragment>
            <ButtonGrid>
              <Button
                color="error"
                background="transparent"
                border="error"
                onClick={() => setShowConfirmDeleteContest(true)}
              >
                <FormattedMessage
                  id="cancelChallengeButton"
                  defaultMessage="Cancel challenge"
                  description="Cancel challenge button"
                />
              </Button>
            </ButtonGrid>
            <ConfirmActionPopup
              isOpen={showConfirmDeleteContest}
              onConfirm={onDeleteContest}
              onAbort={() => setShowConfirmDeleteContest(false)}
            >
              <>
                <Heading tag={Tag.H4}>
                  <FormattedMessage
                    id="cancelChallengeConfirmHeading"
                    defaultMessage="Cancel challenge"
                    description="Heading text in the cancel confirm popup"
                  />
                </Heading>
                <FormattedMessage
                  id="cancelChallengeConfirmBody"
                  defaultMessage="Are you sure you want to cancel the challenge?"
                  description="Body text in the cancel confirm popup"
                />
              </>
            </ConfirmActionPopup>
          </Fragment>
        )}
        <Gap />
        {reference && (
          <Fragment>
            <Heading tag={Tag.H4}>
              <FormattedMessage
                id="pageChallengeArticlesTitle"
                defaultMessage="Get going"
                description="Title for articles section on challenge page"
              />
            </Heading>
            <DragSlider id="reference" slidesPerView={2}>
              {reference.map((item, i) => (
                <ImageCard
                  key={`ref-${i + 1}`}
                  title={getText(item.title)}
                  image={getImage(item.image)}
                  onClick={onNavigate(
                    getRefLink(item._type, item.slug.current)
                  )}
                  size="large"
                />
              ))}
            </DragSlider>
          </Fragment>
        )}
      </ContentWidth>
      <AddChallengeParticipantModal
        contestId={data.id}
        isOpen={isAddParticipantModalOpen}
        onClose={onCloseAddParticipantModal}
        alreadyAddedMembersEmails={data.me.userInvites?.map((invite) => invite.email).filter((email): email is string => email !== null)}
      />
      <RegisterActivityModal
        isOpen={registerModalOpen}
        onClose={onCloseRegisterModal}
        allowAllActivities={firstGoal?.allowAllActivities}
        allowedActivities={firstGoal?.allowedActivities || []}
      />
      <ContentModal
        title={intl.formatMessage({
          id: 'pageChallengeAboutTitle',
          defaultMessage: 'About the challenge',
          description: 'Challenge page about title',
        })}
        isOpen={aboutModalOpen}
        onClose={onCloseAboutModal}
      >
        {description ? <RichText>{description}</RichText> : '-'}
      </ContentModal>
      <ChatModal
        unreadMessages={{
          contest: unreadMessages ?? 0,
        }}
        onOpen={refetchUnreadMessages}
        rooms={
          contestId
            ? [
              {
                title: '',
                chatId: contestId,
              },
            ]
            : []
        }
      />
    </Fragment>
  );
};

export default Challenge;
