import { OverlayView } from '@react-google-maps/api';
import { ContestGoal } from 'models';
import { FC, useMemo } from 'react';

import subGoalMarker from 'assets/vectors/achievement-badge.svg';
import subPinkGoalMarker from 'assets/vectors/pink-achievement-badge.svg';

type Props = {
  goals: ContestGoal[];
  pink?: boolean;
};

const MapSubGoalMarkers: FC<Props> = ({ goals, pink }) => {
  const subGoalMarkers = useMemo(() => {
    const markers: Array<{ lat: number; lng: number }> = [];
    goals.forEach((goal) => {
      goal.subGoals.forEach((subGoal) => {
        if (subGoal.markOnMap) {
          markers.push({
            lat: subGoal.lat,
            lng: subGoal.lng,
          });
        }
      });
    });
    return markers;
  }, [goals]);

  if (!subGoalMarkers.length) {
    return null;
  }

  return (
    <>
      {subGoalMarkers.map((subGoal) => (
        <OverlayView
          key={subGoal.lat + subGoal.lng}
          position={{
            lat: subGoal.lat,
            lng: subGoal.lng,
          }}
          mapPaneName={OverlayView.OVERLAY_LAYER}
          getPixelPositionOffset={(width, height) => ({ x: -(width / 2), y: -(height / 2) })}
        >
          <img
            src={pink ? subPinkGoalMarker : subGoalMarker}
            alt="subgoal marker"
            style={{ width: '32px', height: '32px' }}
          />
        </OverlayView>
      ))}
    </>
  );
};
// OLD CODE, if it above code doesn't work
//   return (
//     <div style={{zIndex: -1}}>
//       {subGoalMarkers.map((subGoal) => (
//         <Marker
//           key={subGoal.lat + subGoal.lng}
//           icon={{
//             url: subGoalMarker,
//             scaledSize: new google.maps.Size(32, 32),
//             anchor: new google.maps.Point(16, 8),
//             origin: new google.maps.Point(0, 0),

//           }}
//           position={{
//             lat: subGoal.lat,
//             lng: subGoal.lng,
//           }}
//           options={{
//             zIndex: -1,
//             anchorPoint: new google.maps.Point(0, 0),
//             animation: google.maps.Animation.DROP,
//           }}
//         />
//       ))}
//     </div>
//   );
// };

export default MapSubGoalMarkers;
