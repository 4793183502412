import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { rtkQueryError } from 'utils/fetch';
import { authReducer } from './authState/authState';
import { adminReducer } from './adminState/adminState';
import { layoutReducer } from './layoutState/layoutState';
import { localizationReducer } from './localizationState/localizationState';
import { baseApi } from './baseApi';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { notificationReducer } from './notificationService/notificationService';
import userReducer from './userState/userState';

// Root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  layout: layoutReducer,
  user: userReducer,
  localization: localizationReducer,
  notification: notificationReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

// Store
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = [baseApi.middleware];
    if (process.env.NODE_ENV !== 'test') {
      middleware.push(rtkQueryError);
    }
    return getDefaultMiddleware().concat(middleware);
  },
});

// Root state
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Selectors
export const authSelector = (state: RootState) => state.auth;
export const adminSelector = (state: RootState) => state.admin;

setupListeners(store.dispatch);
