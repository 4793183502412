import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { ThemeColor } from 'styles';

type ContainerProps = {
  height?: number;
  rounded?: boolean;
  unlocked?: boolean;
  completed?: boolean;
};

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 0, 0.7);
  }
  70% {
    transform: scale(1.02);
    box-shadow: 0 0 10px 10px rgba(255, 255, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 0, 0);
  }
`;

export const BarContainer = styled.div<ContainerProps>`
  height: ${({ height }) => (height ? `${height}px` : '8px')};
  background: ${({ theme }) => theme.colors.backgroundDark};
  border-radius: ${({ rounded }) => (rounded ? '16px 0px 0px 16px' : '4px')};
  width: 100%;
  ${({ unlocked, completed }) =>
    unlocked && !completed &&
    css`
      animation: ${pulse} 2s infinite;
      &:hover {
      cursor: pointer;
    }
    `};
  position: relative;
  overflow: hidden;
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  margin: 14px;
`;

export const ProgressText = styled.div<ContainerProps>`
  ${({ unlocked }) =>
    unlocked &&
    css`
    font-size: 16px;
    font-weight: 900;
    left: 42% !important;
    &:hover {
      cursor: pointer;
      }
      `};

      color: ${({ theme }) => theme.colors.grayText};
      cursor: default;
  white-space: nowrap;
  position: absolute;
  left: 48%;
  transform: translateX(-50%);
`;

type BarProps = {
  color: ThemeColor | string;
  height?: number;
};

export const Bar = styled(motion.div) <BarProps>`
  background: ${({ color, theme }) =>
    color.startsWith('#') ? color : theme.colors[color as ThemeColor]};
  height: ${({ height }) => (height ? `${height}px` : '8px')};
  width: 100%;
  border-radius: 16px 0px 0px 16px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const IconCoin = styled.div`
  border-radius: 100%;
  position: relative;
  right: 8px;
  `;

export const IconContainer = styled.div`
    padding-right: 30px;
  `;

export const LineProgress = styled.div<{ position: number; }>`
  position: absolute;
  margin: 1px 0;
  left: ${({ position }) => `${position}%`};
  top: 0;
  bottom: 0;
  width: 1px;
  overflow: hidden;
  height: 100%;
  background: ${({ theme }) => theme.colors.borderLight};
`;