import {
  ContentImage,
  ContentText,
  ReferenceType,
} from 'models/content/content';

export type LoginValues = {
  email: string;
  password: string;
};

export type Login2FAValues = {
  id: string;
  twoFactorAuthCode: string;
};

export type GenerateMfa = {
  setupCode: string;
  barcodeImageUrl: string;
};

export type MfaValues = {
  code?: string;
};

export type UserValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  verifyPassword: string;
};

export type UserMaintenanceLightDto = {
  userId: string;
  email: string;
  companyAdmin: boolean;
  skandiaUser: boolean;
  userGift: UserGiftDto ;
};

export enum UserGender {
  Unknown = 'Unknown',
  Male = 'Male',
  Female = 'Female',
  NonBinary = 'NonBinary',
}

export type RemoveInviteUserValues = {
  email?: string;
  secretCode: string;
};

export type RegisterInviteUserValues = {
  email: string;
  inviteCode: string;
  language?: string;
};

export type FinalizeInviteUserValues = {
  firstName: string;
  lastName: string;
  gender: UserGender;
  dateOfBirth: string;
  password: {
    password: string;
    verifyPassword: string;
  };
};

export type InviteUserQuery = {
  secretCode?: string;
  body?: FinalizeInviteUserValues;
};

export type ResetPasswordValues = {
  email: string;
};

export type ConfirmResetPasswordValues = {
  resetCode?: string;
  password?: string;
  verifyPassword?: string;
};

export type UserMembership = {
  id: string;
  userId: string;
  moduleAccessRightCollectionId: string;
  companyId: string;
  departmentId: string;
};

export enum ExternalStepProvider {
  GARMIN = 'GARMIN',
  FITBIT = 'FITBIT',
}

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  gender: UserGender;
  dateOfBirth: string;
  lengthCm: number;
  weightKg: number;
  steps: number;
  points: number;
  level: number;
  noRegisteredActivities: number;
  membership: UserMembership;
  companyAdmin: boolean;
  wellrAdmin: boolean;
  profilePictureAssetId: string | null;
  twoFactorAuthEnabled: boolean;
  language: string;
  externalActivities: boolean;
  externalActivitiesProvider: ExternalStepProvider | null;
  gdprConsent: {
    consentGiven: string;
  };
  levelPrivileges: {
    levelNumber: number;
    allowedToUploadProfilePicture: boolean;
    dummyPriviledge: boolean;
    hasLeveledUp: boolean;
  };
  userGift: UserGift;
};

export type UserGift = {
  id: string;
  userId: string;
  giftClaimStatus: GiftClaimStatus;
  received: string | null;
  user: User;
}

export type UserGiftDto = {
  id: string;
  giftClaimStatus: GiftClaimStatus;
  received: string | null;
}

export enum GiftClaimStatus {
  Requested = 'Requested',
  Claimed = 'Claimed',
  Denied = 'Denied',
};

export type Token = {
  accessToken: string;
  refreshToken: string;
};

export type TokenResponse = {
  id: string;
  twoFactorAuthRequired: boolean;
  migratableUser: boolean | null;
  language: string;
  token: Token;
};

export type UserLevel = {
  levelNumber: number;
  pointsMin: number;
  pointsMax: number;
  title: ContentText;
  action: ContentText;
  image: ContentImage | null;
};

export type UserProfileValues = {
  firstName: string;
  lastName: string;
  gender: UserGender;
  dateOfBirth: string;
  lengthCm: number;
  weightKg: number;
  profilePicture?: string;
};

export type UserGreeting = {
  title: ContentText;
  contentString: ContentText;
  buttonText: ContentText;
  referenceType: ReferenceType;
  reference: string;
  image: ContentImage | null;
};

export type RegistrationCodeValues = {
  email: string;
};

export type RegistrationCodeResponse = {
  id: string;
  resetCode: string;
  expires: string;
};

export type UpdateEmailValues = {
  currentEmail: string;
  newEmail: string;
};
